import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div align="center">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEuElEQVQ4yzWUW28aZxCGN45PGBtYwOYMC8tp2eVszuAYjE3cEB/iOI7jxHHlOEpSVYmaOmqqSr1KlYtKvehVf0h/39NZol6MFub75v3mnXlnFIfbw7LLg0PMt7FOUouQT8fZqhu8e9Tjzw8n/PXhmH8+P+Pfv3/g99dTJr0ymVSUlNxV/b5Z7P84ihpcxyu25vOy5vUSjgQpZBM0imneHHT4eLbNp6dDfr0Y8e5ki9+u9ph0i1hyJxQKsKqquATUxrFNSebjZC0NLRNDDayzvObBJcDe9XUSsQjjpsnb4y2up12Ot2s0S1mCAqT6/SysuPBs+InpUTJWkogeQak0MuxMStwbWegC7pHX1twqPgkI2iWIhmgXMzQKOqV0gng4SFAe9vp8uFXvLJHtcZGRYFj1NEpnYHB23uTqqsvW0JrVxpDa1ITSoJTmqFfiZFDm2bDK93tNHvWKjGs5RtUsZjrGliRy87rP6dMGtU4OZbhX5OVVh59/GnJ+0aZd1ekWUowqGfYbea7vN3gzbfNW7P1Rn8tRlYOWwbRpMKikuX7V4/Z2h0vBaPbzKNmCRioV49FxXQBbdKo5Ji2LQ8lkv2XyuF9iKt9p2+S5ZHkq2Z5tV3g6qjHaNDk4rAq7DroeJxAJoIQSIfK6znS/zmjPwtQ1vutXuDzo8XBYZ3fTYLeeZ9qxOL1XYSpnV4cDziZN+uU8eUPj5KRBq1wQ2flR7JZr8RiGkaQuHTwYVOQwJ1knKOR0rFySnmhyr1uiZuoY2RTlQgZTvs8FtFc1qJUz5DNJ1gMbKEurLjpyMRkLi/500WGIjgCOGiZbtTwFs8iL+z0u9vvUaps0i1keSKPGUoaIxFTMDLoWZyigYZGTMr+yRkH4BwU9EgnjFjmYIo9SVsNMRuiODrnfFRWcn/P69gu5lEYlp1HNa3g3fGTkXlTi2pYuUvOhLAjgkgg0EgpRFHr9msHRuM3Zw22unz3gx1ePCQWDtBoVfnn/kssnE8bdKptmdsakbgn9jIZfBmFuyYmiyTTkknHKRppBzeT8QY8v70/5+vGc25tj2rUCoXCYpGR2r13m880hN6dj9gabTAZ1BpsW7UoBI61JhjJ69uuJWBQtkSKdyrDXr3J9MuTt2ZieXF5yuvF4/ThdXpkMH48nXel+k27dpGrlMKVxWWlgKhElGg6hzC2ucHd5dRa46vbilCbNLzqZE7NLERAqLo8sDjmLBgM4nC4pvIOY/LZHMSFjGA1uoMr8K/PLKBGZ14DMrf16JBrlSddCkwvKgpPlVTcrLpUFx9rMVmRx2D7pJPVMnKudGhNRw4Xoc9IqkYxHUYpalJbMrSqAhqRd12MkQxsYsSDVVARNfs8vrzEvgEmRRTsbZ1P8pWSU3XKazVxKFoj8F6WUs0mUqKg7vO4nIHIJydfv83Ncz/Jpt8KHnRIvOgYtWQJWIszNlsXXoyZ/HDQYFZKEAwGK4rdj7VL4PSqKzfvOgoO7Uku3bN2AdKqfS/C8LSNnpWYZesTvFKppWV1H8thN36IpTFTVO/PbsfNLKzMc5c4M8JstOVYFVBWqUTpCLS3b2355QfyLKzbtVbzqt/OSrDi3ZLRs+wXMBp1bdPAfVWZUl3TEdd0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMAGE",
            "title": "IMAGE",
            "src": "/static/0290409848c0848ad1329cfbed1e51fb/da8b6/blog-unleashing-your-vision.png",
            "srcSet": ["/static/0290409848c0848ad1329cfbed1e51fb/43fa5/blog-unleashing-your-vision.png 250w", "/static/0290409848c0848ad1329cfbed1e51fb/c6e3d/blog-unleashing-your-vision.png 500w", "/static/0290409848c0848ad1329cfbed1e51fb/da8b6/blog-unleashing-your-vision.png 1000w", "/static/0290409848c0848ad1329cfbed1e51fb/8a610/blog-unleashing-your-vision.png 1023w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
    </div>
    <h3 {...{
      "id": "one-step-away-from-infinite-possibilities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#one-step-away-from-infinite-possibilities",
        "aria-label": "one step away from infinite possibilities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`One Step Away from Infinite Possibilities`}</h3>
    <p>{`Imagine an untamed ocean of innovation, teeming with creative possibilities. Now,
consider we’ve built you a vessel that sails effortlessly on this vast, expansive
sea. Welcome aboard to Coqui a generative voice AI platform, an awe-inspiring
universe where your creativity knows no bounds and where the traditional constraints
of voice production cease to exist. Yes, that’s right! We are talking about a world
with zero learning curve where you are the master of your vision, and your ideas
come to life in the blink of an eye!`}</p>
    <h3 {...{
      "id": "join-our-robust-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#join-our-robust-community",
        "aria-label": "join our robust community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Join our Robust Community`}</h3>
    <p>{`Our voice AI community is not just a gathering, but a vibrant, throbbing cosmos of
creators, developers, and innovators alike. Together, we’ve hit the impressive
milestone of over 1.5 million downloads per month, making our community a pulsating
hub of idea exchanges and groundbreaking transformations. Together, we innovate,
we disrupt, and we pioneer the future of voice technology.`}</p>
    <h3 {...{
      "id": "crafting-perfection-before-the-curtains-rise",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#crafting-perfection-before-the-curtains-rise",
        "aria-label": "crafting perfection before the curtains rise permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Crafting Perfection: Before the Curtains Rise`}</h3>
    <p>{`The theater of voice acting can be complex. But what if we could navigate it with the
elegance of a seasoned playwright, scripting and finetuning our narrative before the
spotlight shines on our performers? Our voice AI platform empowers you to test scripts
and flow, reducing recording time and providing a solid foundation before engaging
voice actors. It’s not just about getting it right—it’s about crafting the perfect
story with the precision of a maestro!`}</p>
    <h3 {...{
      "id": "a-symphony-of-voices-full-of-emotion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#a-symphony-of-voices-full-of-emotion",
        "aria-label": "a symphony of voices full of emotion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`A Symphony of Voices Full of Emotion`}</h3>
    <p>{`Our robust library is packed with over 60 lifelike voices, each brimming with a range of
emotions. And that’s just the start! With our technology, you can customize and create
an endless array of unique voices, tailoring each one to your specific needs. So,
whether it’s an AI voice for game characters or a prompt-to-speech application, the
sound of innovation is only limited by your imagination.`}</p>
    <h3 {...{
      "id": "innovation-at-your-fingertips",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#innovation-at-your-fingertips",
        "aria-label": "innovation at your fingertips permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Innovation at your Fingertips`}</h3>
    <p>{`Have a look at our voice cloning, where you can replicate any voice from just three
seconds of audio, and add it to your collection. Better yet, with our generative AI
voice platform, you can design your dream voice instead of choosing from a list.
Add to that our Timeline Editor, Multiple Takes, Project Management features, and
the forthcoming Script Imports and Team Collaboration, and you have the ultimate
tool for bringing your vision to life.`}</p>
    <h3 {...{
      "id": "ready-set-innovate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ready-set-innovate",
        "aria-label": "ready set innovate permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Ready, Set, Innovate!`}</h3>
    <p>{`The time to plunge into this dynamic new world is now. Let’s collectively pioneer a
new era where our visions come alive, and technology propels our creativity to
uncharted territories. Engage with our generative AI voice platform, share your
insights, and help us shape the voice technology of the future. Together, we’ll
compose a symphony that resonates with the rhythm of innovation. Join us and play
your part in shaping the soundscape of tomorrow.`}</p>
    <div align="center">
      <Link to="https://app.coqui.ai/auth/signup" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "137px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.306569343065696%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMAGE",
              "title": "IMAGE",
              "src": "/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-unleashing-your-vision-try-now-for-free.png",
              "srcSet": ["/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-unleashing-your-vision-try-now-for-free.png 137w"],
              "sizes": "(max-width: 137px) 100vw, 137px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </Link>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      